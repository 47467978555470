import React from 'react'

import Layout from '../components/Layout'
import TMN from '../components/TMN'

export default ({ location, pageContext }) => (
  <Layout
    pageTitle={pageContext.pageTitle}
    location={location}
    locale={pageContext.locale}
  >
    <TMN locale={pageContext.locale} />
  </Layout>
)
